import { axios } from '@/plugins';

const fetchIncentives = async ({ rootGetters, commit }) => {
  try {
    const requestUrl = rootGetters['info/getLinks']?.incentives;
    if (!requestUrl) return {};

    const { data } = await axios.get(requestUrl);

    commit('SET_INCENTIVES', data);
    return data;
  } catch (err) {
    throw err;
  }
};

export default {
  fetchIncentives,
};
